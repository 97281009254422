import { createContext, useContext } from 'react'

export type PageName =
  | 'home'
  | 'search'
  | 'workspace'
  | 'cms'
  | 'projects'
  | 'info/content'

const PageNameContext = createContext<PageName>('home')

export const PageNameProvider = PageNameContext.Provider

export function usePageName() {
  return useContext(PageNameContext)
}
