import clsx from 'clsx'
import React, { useEffect, useRef } from 'react'

import { makeStyles, useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'

import { useTranslation } from 'src/contexts/i18n'
import { AcfProject } from 'src/contexts/wordpress'

import { ErrorPage } from '../../shared/ErrorPage'
import { Description } from './Description'
import { Manuscripts } from './Manuscripts'
import { Metatags } from './Metatags'
import { Overview } from './Overview'
import { Publications } from './Publications'

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    scrollMarginTop: '200px',
    scrollBehavior: 'smooth',
  },
}))

interface Props {
  className?: string
  project: AcfProject | undefined
}

export function DetailPage(props: Props) {
  const { className, project } = props
  const cls = useStyles()
  const theme = useTheme()
  const mobile = useMediaQuery(theme.breakpoints.down('xs'), { noSsr: true })
  const { t } = useTranslation()
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (mobile && ref.current) {
      ref.current.scrollIntoView()
    }
  }, [])

  if (project) {
    return (
      <div className={clsx(cls.root, className)} ref={ref}>
        <Metatags project={project} />
        <Overview project={project} />
        <Description project={project} />
        <Manuscripts project={project} />
        <Publications project={project} />
      </div>
    )
  }

  return <ErrorPage errorMessage={t('loadingError')} />
}
