import { createContext, useContext, useEffect, useState } from 'react'

// Data structure provided by /api/tracking.
// Assembled from environment variables.
export interface FeatureFlagConfig {
  cms: boolean
  retroDescDisplay: boolean
  annotation: boolean
}

export class FeatureFlags {
  public isLoaded = false
  private eventTarget = new EventTarget()
  private flags = {
    cms: false,
    retroDescDisplay: false,
    annotation: false,
  }

  constructor() {
    this.load()
  }

  addEventListener(type: string, handler: () => void) {
    this.eventTarget.addEventListener(type, handler)

    return () => {
      this.eventTarget.removeEventListener(type, handler)
    }
  }

  async load() {
    const response = await fetch('/api/features')

    if (!response.status) {
      console.error('error fetching feature flags')
    }

    const config = (await response.json()) as FeatureFlagConfig
    this.flags.cms = config.cms
    this.flags.retroDescDisplay = config.retroDescDisplay
    this.flags.annotation = config.annotation
    this.isLoaded = true
    this.eventTarget.dispatchEvent(new CustomEvent('loaded'))
  }

  get cms() {
    return this.flags.cms
  }

  get retroDescDisplay() {
    return this.flags.retroDescDisplay
  }

  get annotation() {
    return this.flags.annotation
  }
}

export const FeatureFlagsContext = createContext<FeatureFlags | undefined>(
  undefined,
)

export function useFeatureFlags() {
  const flags = useContext(FeatureFlagsContext) as FeatureFlags
  const [loaded, setLoaded] = useState(flags.isLoaded)

  useEffect(() => {
    if (!loaded) {
      return flags.addEventListener('loaded', () => setLoaded(true))
    }
  }, [])

  return flags
}
