import parse, { Element, HTMLReactParserOptions } from 'html-react-parser'
import React from 'react'
import { Link as RouterLink } from 'react-router-dom'

import Link from '@material-ui/core/Link'

import { relative } from 'src/utils/relative'

interface Props {
  html: string
}

/**
 * Renders an HTML string to react element tree.
 */

export function HtmlRenderer(props: Readonly<Props>) {
  const { html } = props

  const options: HTMLReactParserOptions = {
    replace(node: any) {
      if (!(node as Element).attribs) {
        return
      }

      const text = (node as any).children[0].data as string

      if (node.name === 'intern') {
        return (
          <Link
            component={RouterLink}
            color="primary"
            to={relative(node.attribs.href)}
            tabIndex={0}
          >
            {text}
          </Link>
        )
      }

      if (node.name === 'a') {
        return (
          <Link
            color="primary"
            target="_blank"
            rel="noopener"
            href={node.attribs.href}
          >
            {text}
          </Link>
        )
      }
    },
  }
  const rendered = parse(html, options)

  return <>{rendered}</>
}
