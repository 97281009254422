import React from 'react'
import { useQuery } from 'react-query'
import { useSelector } from 'react-redux'

import { useTranslation } from 'src/contexts/i18n'
import { selectors } from 'src/contexts/state'
import { getProjectPages } from 'src/contexts/wordpress'
import {
  getDetailProject,
  getRunningTime,
  isFinished,
  sortDateDesc,
  useParsedParams,
} from 'src/utils/projectsUtils'

import { ErrorPage } from '../shared/ErrorPage'
import { DetailPage } from './Detailpage/index'
import { EntryPage } from './EntryPage/index'

interface Props {
  className?: string
}

export function Projects({ className }: Readonly<Props>) {
  const { projectid } = useParsedParams()
  const { t } = useTranslation()
  const projectStatus = useSelector(selectors.getProjectStatus)

  const { data: projects, status: pageStatus } = useQuery('acfPages', () => {
    return getProjectPages()
  })

  if (pageStatus === 'error') {
    return <p id="hsp-projects-main">{t('loadingError')}</p>
  }

  if (pageStatus === 'loading') {
    return <p id="hsp-projects-main">...{t('loading')}</p>
  }

  if (projects) {
    // add running time and status to projects
    projects.forEach((projectEntry) => {
      projectEntry.status = isFinished(projectEntry.end)
      projectEntry.runningTime = getRunningTime(
        projectEntry.start,
        projectEntry.end,
      )
      return projectEntry
    })
    projects.sort(sortDateDesc)

    const project = getDetailProject(projects, projectid)

    return (
      <div id="hsp-projects-main">
        {projectid ? (
          <DetailPage className={className} project={project} />
        ) : (
          <EntryPage
            className={className}
            projects={projects}
            projectStatus={projectStatus}
          />
        )}
      </div>
    )
  }
  return <ErrorPage id="hsp-projects-main" errorMessage={t('pagenotfound')} />
}
