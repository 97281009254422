import clsx from 'clsx'
import React from 'react'
import { Link } from 'react-router-dom'

import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import { makeStyles } from '@material-ui/core/styles'

import { useTranslation } from 'src/contexts/i18n'
import { relative } from 'src/utils/relative'

const useStyles = makeStyles((theme) => ({
  root: {},
  link: {
    color: theme.palette.whiteSmoke.main,
    padding: 0,
    justifyContent: 'center',
    '&:focus-visible': {
      outline: `${theme.spacing(0.5)}px solid ${
        theme.palette.white.main
      } !important`,
      outlineOffset: `${theme.spacing(-0.5)}px !important`,
    },
  },
}))

interface Props {
  className?: string
}

export function Miscellaneous({ className }: Props) {
  const cls = useStyles()
  const { t } = useTranslation()

  return (
    <List className={clsx(cls.root, className)} component="nav">
      <ListItem
        button={true}
        className={cls.link}
        component={Link}
        role="link"
        tabIndex={0}
        to={relative(t('links.legal'))}
      >
        {t('sidebar.misc.legal')}
      </ListItem>
      <ListItem
        button={true}
        className={cls.link}
        component={Link}
        role="link"
        to={relative(t('links.accessibility'))}
        id="accessibility"
        tabIndex={0}
      >
        {t('sidebar.misc.accessibility')}
      </ListItem>
    </List>
  )
}
