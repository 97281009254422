import React, { useEffect, useRef } from 'react'

import { Navigation } from 'src/components/navigation'
import { useModules, useSetupModulesForPage } from 'src/contexts/modules'
import { PageNameProvider } from 'src/contexts/pageName'
import { useTrackPageView } from 'src/contexts/tracking'

export function Search() {
  const modules = useModules()
  const mainContainer = useRef(document.createElement('div'))
  mainContainer.current.style.width = '100%'
  const topBarToolsContainer = useRef(document.createElement('div'))
  topBarToolsContainer.current.style.width = '100%'

  useSetupModulesForPage()
  useTrackPageView('Search')

  useEffect(() => {
    modules.search.mount({
      main: mainContainer.current,
      searchBarOrOverviewNavigation: topBarToolsContainer.current,
    })
  }, [])

  return (
    <PageNameProvider value="search">
      <Navigation
        main={mainContainer.current}
        topBarTools={topBarToolsContainer.current}
      />
    </PageNameProvider>
  )
}
