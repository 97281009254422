/**
 * This file sets up the web modules (search, workspace, projects) and provides
 * functions to integrate the modules into the app flow.
 */
import { HspSearch } from 'hsp-fo-search/types'
import { HspWorkspace, WorkspaceResource } from 'hsp-fo-workspace/types'
import { AnyWebModule, WebModuleLocation } from 'hsp-web-module'
import urlJoin from 'proper-url-join'
import {
  createContext,
  useContext,
  useEffect,
  useLayoutEffect,
  useReducer,
} from 'react'
import { useLocation, useNavigate } from 'react-router'
import { v4 as uuidv4 } from 'uuid'

import { getWebModuleLanguage, useTranslation } from 'src/contexts/i18n'
import { actions, useDispatch } from 'src/contexts/state'
import { useTracker } from 'src/contexts/tracking'
import { relative } from 'src/utils/relative'

import { useFeatureFlags } from '../features'
import { createSearch } from './search'
import { createWorkspace } from './workspace'

export interface HspModules {
  search: HspSearch
  workspace: HspWorkspace
}

/**
 * Creates instances of the web modules used in the app.
 */
export function createHspModules(): HspModules {
  /**
   * Callback function for the modules that determines how
   * absolute urls are built.
   *
   * See: https://code.dev.sbb.berlin/HSP/hsp-web-module#47-routing
   */
  function makeCreateAbsoluteURL(mountPath: string) {
    return function ({ pathname, search, hash }: WebModuleLocation) {
      const url = new URL(urlJoin(mountPath, pathname), location.origin)
      url.search = search
      url.hash = hash
      return url
    }
  }

  return {
    search: createSearch(makeCreateAbsoluteURL('/search')),
    workspace: createWorkspace(makeCreateAbsoluteURL('/workspace')),
  }
}

/**
 * React context to make the modules available within the apps component tree.
 */
export const ModuleContext = createContext<HspModules | undefined>(undefined)

/**
 * Returns the module instances provided by the ModuleContext.
 */
export function useModules() {
  return useContext(ModuleContext) as HspModules
}

/**
 * The following hooks integrate the modules into the React app flow.
 * They all handle the general problem that the modules are plain javascript
 * objects that do not respond to React's life cycle methods.
 */

/**
 * Creates a permalink (href) of a workspace resource.
 */
export function createWorkspacePermalink(type: string, id: string) {
  const url = new URL('/workspace', window.location.origin)
  url.search = new URLSearchParams({ type, id }).toString()
  return url.href
}

/**
 * Helper to trigger rerenderings
 */
export function useForceUpdate() {
  const [, forceUpdate] = useReducer((x) => x + 1, 0)
  return forceUpdate
}

/**
 * Listens to language updates in the i18next object
 * and sets the new language to each module
 */
export function useUpdateLanguage() {
  const forceUpdate = useForceUpdate()
  const modules = useModules()
  const { i18n } = useTranslation()

  useEffect(() => {
    const language = getWebModuleLanguage(i18n.language)
    Object.values(modules).forEach((m: AnyWebModule) => m.setLanguage(language))
    // perform a rerendering
    forceUpdate()
  }, [i18n.language])
}

/**
 * Listens for events dispatched by the modules and performs
 * the respective actions.
 *
 * See: https://code.dev.sbb.berlin/HSP/hsp-web-module#34-events
 * See also the event API documentation of the modules.
 */
export function useWireModules() {
  const { search, workspace } = useModules()
  const dispatch = useDispatch()
  const tracker = useTracker()

  /**
   * A helper function that validates if the resource is not already present
   * in the selected resources of the search module, then add it.
   */

  function addResourceToSearch({ manifestId, type }: WorkspaceResource): void {
    if (!manifestId) return

    const ids = search.getSelectedResources().map(({ id }) => id)
    if (!ids.includes(manifestId)) {
      search.setSelectedResources([
        ...search.getSelectedResources(),
        { id: manifestId, query: undefined, type },
      ])
    }
  }

  useEffect(() => {
    /**
     * For clarity and documentation purposes
     * the following code is somewhat redundant.
     */

    // Collects all listener removal functions
    const removeFns: (() => void)[] = []

    const selectOrOpenResourceClicked = (e: { detail: WorkspaceResource }) => {
      const res = e.detail
      // then create a permalink for that resource
      const permalink = createWorkspacePermalink(res.type, res.id)
      // and add the resource info to the workspace,
      const resources = workspace.getResources()
      const notInWorkspace = resources.every(
        ({ manifestId }) => res.id !== manifestId,
      )
      if (notInWorkspace) {
        if (res.type.includes('description')) {
          workspace.addResource({
            ...res,
            permalink,
            id: res.id,
          })
        } else {
          workspace.addResource({
            ...res,
            permalink,
            manifestId: res.id,
            id: 'hsp-window-' + uuidv4(),
          })
        }

        // and add the resource info to the search module itself
        // so it will be marked as selected
        search.setSelectedResources([
          ...search.getSelectedResources(),
          { ...res, query: undefined }, // reset query after adding to workspace
        ])
        // and update the number in the workspace button in the sidebar.
        dispatch(
          actions.setWorkspaceBadgeCount(workspace.getResources().length),
        )
      }
    }

    // If the user clicks the select button in the resource action cards
    removeFns.push(
      search.addEventListener(
        'selectResourceClicked',
        selectOrOpenResourceClicked,
      ),
    )

    // If the user clicks the unselect button in the resource action cards
    removeFns.push(
      search.addEventListener('unselectResourceClicked', (e) => {
        // then remove the resource info from the workspace
        if (e.detail.type.includes('description')) {
          workspace.removeResource(e.detail)
        } else {
          // remove including all iiif duplicates
          workspace.removeResources(e.detail.id) // in search id is a manifestId
        }
        // and remove the resource info from the search module itself
        // so it becomes unmarked.
        search.setSelectedResources(
          search.getSelectedResources().filter((r) => r.id !== e.detail.id),
        )
        // and update the number in the workspace button in the sidebar.
        dispatch(
          actions.setWorkspaceBadgeCount(workspace.getResources().length),
        )
      }),
    )

    /**
     * This basically performs the same actions as the "selectResourceClicked"
     * handler above.
     *
     * The page transition that is intended by the event is performed in the
     * useModuleRouting hook.
     */
    removeFns.push(
      search.addEventListener(
        'openResourceClicked',
        selectOrOpenResourceClicked,
      ),
    )

    // If a resource was added to Mirador by user action (e.g. album add button)
    removeFns.push(
      workspace.addEventListener('resourceAddedToMirador', (e) => {
        const res = e.detail
        // then prevent the resource from automatically getting added to the workspace state
        e.preventDefault()
        // so we can create a permalink to the resource
        if (res.manifestId) {
          const permalink = createWorkspacePermalink(res.type, res.manifestId)
          // and manually add it to the workspace state
          workspace.addResource({ ...res, permalink })
          addResourceToSearch(res)
          // and update the number in the workspace button in the sidebar.
          dispatch(
            actions.setWorkspaceBadgeCount(workspace.getResources().length),
          )
        }
      }),
    )

    // If a resource was updated to Mirador by user action (e.g. via permalink)
    removeFns.push(
      workspace.addEventListener('miradorResourceUpdated', (e) => {
        const res = e.detail
        e.preventDefault()
        addResourceToSearch(res)
      }),
    )

    // If a resource was removed form Mirador by user action (e.g. window closed button)
    removeFns.push(
      workspace.addEventListener('resourceRemovedFromMirador', (e) => {
        // set isMaximized to false if window gets closed
        dispatch(actions.setIsMiradorMaximized(false))
        // then remove the resource info from the search module itself
        // so it becomes unmarked.
        const resources = workspace.getResources()
        const selectedResources = search.getSelectedResources()
        const removeResourceById = (resourceId: string | undefined) =>
          selectedResources.filter((r) => r.id !== resourceId)
        if (e.detail.type.includes('description')) {
          search.setSelectedResources(removeResourceById(e.detail.id))
        } else {
          const isLastInstanceOfResourceRemoved =
            resources.filter(
              ({ manifestId }) => e.detail.manifestId === manifestId,
            ).length === 1
          if (isLastInstanceOfResourceRemoved) {
            search.setSelectedResources(removeResourceById(e.detail.manifestId))
          }
        }

        // and update the number in the workspace button in the sidebar.
        const updateBadge = () =>
          dispatch(
            actions.setWorkspaceBadgeCount(workspace.getResources().length),
          )
        // Defer badge update unit the event was processed by the workspace
        setTimeout(updateBadge)
      }),
    )

    // If the user clicks the maximize button in Mirador
    removeFns.push(
      workspace.addEventListener('miradorWindowSizeChanged', (e) => {
        const isMiradorMaximized = e.detail === 'maximized'
        dispatch(actions.setIsMiradorMaximized(isMiradorMaximized))
      }),
    )

    // If the user clicks the search button to perform a search
    removeFns.push(
      search.addEventListener('searchButtonClicked', (e) => {
        // then track that event and the search term.
        tracker.trackSiteSearch('Search', e.detail)
      }),
    )

    // This runs when the calling component will be unmounted.
    return () => {
      // Remove all event listeners added above.
      removeFns.forEach((remove) => remove())
    }
  }, [])
}

/**
 * 1) Listens for routing events dispatched by the modules and
 * routes the app to the respective location.
 *
 * 2) Listens for route updates of the app and updates the web
 * module locations of the modules.
 *
 * See https://code.dev.sbb.berlin/HSP/hsp-web-module#27-routing
 */
export function useModuleRouting() {
  const forceUpdate = useForceUpdate()
  const modules = useModules()
  const { search, workspace } = modules
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useDispatch()

  useEffect(() => {
    // Check that self routing of the modules is turned off.
    // I.e. the app will have full control over routing.
    Object.values(modules).forEach((m: AnyWebModule) => {
      if (m.getConfig().enableRouting) {
        throw new Error(
          'startRouting: routing must be disabled for the modules.',
        )
      }
    })

    // Collects listener removal functions.
    const removeFns: (() => void)[] = []

    Object.values(modules).forEach((m: AnyWebModule) => {
      // If a user clicks on a link (or link button) within a module's ui
      removeFns.push(
        m.addEventListener('linkClicked', (e) => {
          // then prevent the browser from handle the link click
          e.preventDefault()
          // and let React Router change to this route.
          navigate(relative(e.detail.href), { preventScrollReset: true })
        }),
      )
    })

    /**
     * NOTE: This event is handled in useWireModule too.
     *
     * If the user clicks on a resource to open it in the workspace
     */
    removeFns.push(
      search.addEventListener('openResourceClicked', () => {
        // than get the current url of the workspace module
        const workspaceHref = workspace
          .getConfig()
          .createAbsoluteURL(workspace.getLocation()).href
        // and let React Router change to this route.
        navigate(relative(workspaceHref), { preventScrollReset: true })
      }),
    )

    removeFns.push(
      search.addEventListener('backToWorkspace', () => {
        // than get the current url of the workspace module
        const workspaceHref = workspace
          .getConfig()
          .createAbsoluteURL(workspace.getLocation()).href
        // and let React Router change to this route.
        navigate(relative(workspaceHref), { preventScrollReset: true })
      }),
    )

    removeFns.push(
      workspace.addEventListener('openResourceInSearchClicked', (e) => {
        const searchLocation = search
          .getConfig()
          .createAbsoluteURL(search.getLocation())
        const params = new URLSearchParams(search.getLocation().search)
        params.set('fromWorkspace', 'true')
        params.set('hspobjectid', e.detail.hspobjectid)
        navigate(`${searchLocation.pathname}?${params.toString()}`, {
          preventScrollReset: true,
        })
      }),
    )

    // This runs when the calling component will be unmounted.
    return () => {
      // Remove all event listeners added above.
      removeFns.forEach((remove) => remove())
    }
  }, [])

  // If the route of the app has changed (see the dependency list of this effect)
  // then check what module was targeted and update it's web module location.
  useEffect(() => {
    if (location.pathname.startsWith('/search')) {
      search.setLocation({
        // Cut the mount path from the url
        // because this is not relevant to the module.
        pathname: location.pathname.replace('/search', ''),
        hash: location.hash,
        search: location.search,
      })
    } else if (location.pathname.startsWith('/workspace')) {
      const params = new URLSearchParams(location.search)
      const type = params.get('type') as
        | 'hsp:description'
        | 'hsp:description_retro'
        | 'iiif:manifest'
      const id = params.get('id')
      const page = params.get('page') as unknown as number

      if (type && id) {
        const permalink = createWorkspacePermalink(type, id)
        if (type.includes('description')) {
          workspace.addResource({ id, type, permalink })
        } else {
          workspace.addResource({
            type,
            manifestId: id,
            // canvasIndex is zero-based, page is 1-based
            canvasIndex: page ? page - 1 : undefined,
            permalink,
            id: 'hsp-window-' + uuidv4(),
          })
        }

        dispatch(
          actions.setWorkspaceBadgeCount(workspace.getResources().length),
        )
      }

      navigate('/workspace', { preventScrollReset: true })
    }
    // perform a rerendering
    forceUpdate()
  }, [location.pathname, location.search, location.hash])
}

function useUpdateFeatures() {
  const features = useFeatureFlags()
  const modules = useModules()
  useEffect(() => {
    Object.values(modules).forEach((module) => {
      if (module.setFeatures) {
        module.setFeatures(features)
      }
    })
  }, [features.isLoaded])
}

/**
 * Unmounts all modules if the calling component gets unmounted.
 */
export function useUnmountModulesOnExit() {
  const modules = useModules()

  /**
   * Using the layout effect ensures that the effect performs
   * before the DOM nodes disappears. This is neccesary for the
   * workspace module because Mirador queries for its container
   * node while unmounting.
   *
   * See: https://github.com/ProjectMirador/mirador/blob/master/src/lib/MiradorViewer.js#L43
   */
  useLayoutEffect(() => {
    return () => {
      modules.workspace.unmount()
      modules.search.unmount()
    }
  }, [])
}

/**
 * Performs all of the above hooks at once.
 *
 * It is meant to be used by the page components that assemble
 * a page that consists of one or multiple modules and other components.
 *
 * When a page gets unmounted then module related stuff will be cleared:
 *    - listeners will be removed
 *    - all modules will be unmounted
 */
export function useSetupModulesForPage() {
  // order matters
  useUpdateLanguage()
  useUpdateFeatures()
  useWireModules()
  useModuleRouting()
  useUnmountModulesOnExit()
}
