import React, { useEffect, useRef } from 'react'

import { Navigation } from 'src/components/navigation'
import { useSetMetatag, useSetTitle } from 'src/contexts/Metatags'
import { useTranslation } from 'src/contexts/i18n'
import { useModules, useSetupModulesForPage } from 'src/contexts/modules'
import { PageNameProvider } from 'src/contexts/pageName'
import { useTrackPageView } from 'src/contexts/tracking'

export function Home() {
  const { t } = useTranslation()
  const modules = useModules()

  useSetupModulesForPage()
  useTrackPageView('Home')

  const mainContainer = useRef(document.createElement('div'))
  mainContainer.current.style.width = '100%'
  mainContainer.current.style.display = 'flex'

  const searchBarContainer = useRef(document.createElement('div'))
  searchBarContainer.current.setAttribute('id', 'hsp-home-search-bar-anchor')

  const title = `Handschriftenportal: ${t('topBar.logoLink')}`
  const desc = t('description')

  useSetTitle(title)
  useSetMetatag({ key: 'name', value: 'description', content: desc })

  useEffect(() => {
    if (document.getElementById('hsp-home-search-bar-anchor')) {
      modules.search.mount({
        searchBar: document.getElementById('hsp-home-search-bar-anchor')!,
      })
    }
  }, [])

  return (
    <PageNameProvider value="home">
      <Navigation
        main={mainContainer.current}
        searchBar={searchBarContainer.current}
        topBarTools={undefined}
        module={'home'}
      />
    </PageNameProvider>
  )
}
