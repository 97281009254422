import urlJoin from 'proper-url-join'
import React from 'react'
import { useQueryClient } from 'react-query'

import { useTranslation } from 'src/contexts/i18n'
import { MenuProps } from 'src/contexts/state'
import { wordpressEndpoint } from 'src/contexts/wordpress'

import { WordpressMenuItem, fetchWordpressMenus } from '../contexts/wordpress'
import { getSlug } from './getSlug'

export default function useGetMenus() {
  const { i18n } = useTranslation()
  const reactQueryClient = useQueryClient()

  return React.useCallback(async () => {
    function wordpressMenuToItem(wpMenu: WordpressMenuItem): MenuProps {
      const slug = getSlug(new URL(wpMenu.url).pathname)
      const url = new URL(urlJoin('/info', '/' + slug), window.location.origin)
      url.search = ''
      url.hash = ''
      return {
        id: wpMenu.ID.toString(),
        label: wpMenu.title,
        children: wpMenu.child_items?.map(wordpressMenuToItem) || [],
        link: url.href,
      }
    }

    // Problem is we can not use useQuery at this point because there is
    // no react context here.
    const language = i18n.resolvedLanguage
    if (language) {
      return reactQueryClient
        .fetchQuery(['wordpressMenus', language], () => {
          return fetchWordpressMenus(wordpressEndpoint, language)
        })
        .then((menus) => menus.items.map(wordpressMenuToItem))
    }
  }, [i18n.language])
}
