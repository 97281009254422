import clsx from 'clsx'
import React, { useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import Drawer from '@material-ui/core/Drawer'
import { makeStyles } from '@material-ui/core/styles'

import { Cms } from 'src/components/cms/index'
import { Home } from 'src/components/home/index'
import { selectors } from 'src/contexts/state'

import { Projects } from '../../projects/index'
import { SideBar } from '../shared/SideBar'
import { SideBarButton } from '../shared/SideBarButton'
import { Footer } from './Footer'
import { TopBar } from './TopBar'

const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: 'stretch',
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100%',
  },
  sideBar: {
    flexShrink: 0,
    paddingTop: theme.spacing(12),
    whiteSpace: 'nowrap',
  },
  sideBarClosed: {
    width: theme.spacing(8),
    transition: theme.transitions.create('width', {
      duration: theme.transitions.duration.leavingScreen,
      easing: theme.transitions.easing.sharp,
    }),
  },
  sideBarOpen: {
    width: theme.spacing(30),
    transition: theme.transitions.create('width', {
      duration: theme.transitions.duration.enteringScreen,
      easing: theme.transitions.easing.sharp,
    }),
  },
  main: {
    height: 'inherit',
  },
  mainWhenSideBarClosed: {
    marginLeft: theme.spacing(8),
    transition: theme.transitions.create('margin', {
      duration: theme.transitions.duration.leavingScreen,
      easing: theme.transitions.easing.sharp,
    }),
  },
  mainWhenSideBarOpen: {
    marginLeft: theme.spacing(30),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  sideBarDiv: {
    height: 'inherit',
    overflowX: 'hidden',
    overflowY: 'auto',
  },
  navFrame: {
    position: 'sticky',
    zIndex: 1200,
    height: theme.mixins.toolbar.minHeight,
    flexShrink: 0,
  },
  content: {
    flexGrow: 1,
    flexShrink: 0,
    '&:focus-visible': {
      outlineOffset: `${theme.spacing(-0.5)}px !important`,
    },
  },
}))

interface Props {
  className?: string
  main?: Element
  searchBar?: Element
  topBarTools?: Element
  module?: string
}

export function WebNavigation(props: Readonly<Props>) {
  const cls = useStyles()
  const sideBarOpen = useSelector(selectors.getSidebarOpen)
  const mainRef = useRef<HTMLElement>(null)
  const location = useLocation()

  const isInWorkspace = location.pathname.includes('workspace')
  const isMiradorMaximized = useSelector(selectors.getIsMiradorMaximized)
  const isWorkspaceMaximized = isMiradorMaximized && isInWorkspace

  useEffect(() => {
    if (mainRef.current && props.main) {
      mainRef.current.appendChild(props.main)
    }
  }, [])

  return (
    <>
      <div
        data-testid="navigation-web"
        className={clsx(cls.root, props.className)}
      >
        <div className={cls.navFrame}>
          {!isWorkspaceMaximized && <SideBarButton />}
          <Drawer
            open={sideBarOpen}
            variant="permanent"
            classes={{
              paper: clsx(cls.sideBar, {
                [cls.sideBarOpen]: sideBarOpen,
                [cls.sideBarClosed]: !sideBarOpen,
              }),
            }}
          >
            <div className={cls.sideBarDiv}>
              <SideBar mobile={false} />
            </div>
          </Drawer>
          <TopBar topBarTools={props.topBarTools} />
        </div>
        <div
          id="content"
          tabIndex={-1}
          className={clsx(cls.content, 'addFocusableWithOutline', {
            [cls.mainWhenSideBarOpen]: sideBarOpen && !isWorkspaceMaximized,
            [cls.mainWhenSideBarClosed]: !sideBarOpen && !isWorkspaceMaximized,
          })}
        >
          <main ref={mainRef} className={cls.main}>
            {props.module === 'home' && props.searchBar && (
              <Home searchBar={props.searchBar} />
            )}
            {props.module === 'cms' && <Cms />}
            {props.module === 'projects' && <Projects />}
          </main>
        </div>
      </div>
      {props.module !== 'workspace' && (
        <div
          className={clsx('addFocusableWithOutline', {
            [cls.mainWhenSideBarOpen]: sideBarOpen,
            [cls.mainWhenSideBarClosed]: !sideBarOpen,
          })}
        >
          <Footer />
        </div>
      )}
    </>
  )
}
