import { Config, CreateHspSearch } from 'hsp-fo-search/types'
import fetch from 'isomorphic-unfetch'

declare global {
  const createHspSearch: CreateHspSearch
}

export function createSearch(createAbsoluteURL: Config['createAbsoluteURL']) {
  return createHspSearch({
    customFetch: fetch,
    classNamePrefix: 'hsp-search',
    createAbsoluteURL,
    enableRouting: false,
    discoveryEndpoint: '/api/',
    cacheOptions: {
      staleTime: 3 * 60 * 1000,
      retry: 3,
    },
    theme: {},
  })
}
