import { configureStore } from '@reduxjs/toolkit'

import { AnyAction, createAction, isType } from 'src/utils/stateUtils'

export { useDispatch, useSelector } from 'react-redux'

export interface ImageSet {
  url: string
  ident: string
  info: string
}

export interface MenuProps {
  id: string
  link: string
  label: string
  children: MenuProps[]
}

export interface MenusProps {
  de: MenuProps[]
  en: MenuProps[]
}

export interface State {
  sidebarOpen: boolean
  workspaceBadgeCount: number
  imageSets?: ImageSet[]
  projectStatus: string
  menus?: MenusProps
  isMiradorMaximized?: boolean
}

export const actions = {
  setState: createAction<State>('SET_STATE'),
  toggleSidebar: createAction<void>('TOGGLE_SIDEBAR'),
  setWorkspaceBadgeCount: createAction<number>('SET_WORKSPACE_BADGE_COUNT'),
  setImageSets: createAction<State['imageSets']>('SET_IMAGE_SETS'),
  setProjectStatus: createAction<State['projectStatus']>('SET_PROJECT_STATUS'),
  setMenus: createAction<State['menus']>('SET_MENUS'),
  setIsMiradorMaximized: createAction<boolean>('SET_IS_MAXIMIZED'),
}

export const selectors = {
  getSidebarOpen: (state: State) => state.sidebarOpen,
  getWorkspaceBadgeCount: (state: State) => state.workspaceBadgeCount,
  getImageSets: (state: State) => state.imageSets,
  getProjectStatus: (state: State) => state.projectStatus,
  getMenus: (state: State) => state.menus,
  getIsMiradorMaximized: (state: State) => state.isMiradorMaximized,
}

export const defaultState: State = {
  sidebarOpen: false,
  workspaceBadgeCount: 0,
  isMiradorMaximized: false,
  projectStatus: 'running',
}

export function reducer(state = defaultState, action: AnyAction): State {
  if (isType(action, actions.setState)) {
    return action.payload
  }

  if (isType(action, actions.toggleSidebar)) {
    return { ...state, sidebarOpen: !state.sidebarOpen }
  }

  if (isType(action, actions.setWorkspaceBadgeCount)) {
    return { ...state, workspaceBadgeCount: action.payload }
  }

  if (isType(action, actions.setImageSets)) {
    return { ...state, imageSets: action.payload }
  }

  if (isType(action, actions.setProjectStatus)) {
    return { ...state, projectStatus: action.payload }
  }
  if (isType(action, actions.setMenus)) {
    return { ...state, menus: action.payload }
  }

  if (isType(action, actions.setIsMiradorMaximized)) {
    return { ...state, isMiradorMaximized: action.payload }
  }

  return state
}

export const makeStore = (initialState?: State) =>
  configureStore({ reducer, preloadedState: initialState, devTools: true })
