import React, { useEffect, useRef } from 'react'

import { useTheme } from '@material-ui/core/styles'

import { Navigation } from 'src/components/navigation'
import { useFeatureFlags } from 'src/contexts/features'
import { useModules, useSetupModulesForPage } from 'src/contexts/modules'
import { PageNameProvider } from 'src/contexts/pageName'
import { useTrackPageView } from 'src/contexts/tracking'

export function Workspace() {
  const modules = useModules()
  const theme = useTheme()
  const featureFlags = useFeatureFlags()
  const topBarToolsContainer = useRef(document.createElement('div'))
  topBarToolsContainer.current.style.width = '100%'
  const mainContainer = useRef(document.createElement('div'))
  mainContainer.current.style.height = `calc(100vh - ${theme.mixins.toolbar.minHeight}px)`
  mainContainer.current.style.position = 'relative'
  mainContainer.current.style.zIndex = '1200'

  useSetupModulesForPage()
  useTrackPageView('Workspace')

  useEffect(() => {
    modules.workspace.mount({ main: mainContainer.current })
    modules.workspace.addAnnotation(featureFlags.annotation)
    modules.search.mount({ searchBar: topBarToolsContainer.current })
  }, [])

  return (
    <PageNameProvider value="workspace">
      <Navigation
        main={mainContainer.current}
        topBarTools={topBarToolsContainer.current}
        module={'workspace'}
      />
    </PageNameProvider>
  )
}
