import React, { useEffect, useRef } from 'react'

import { Navigation } from 'src/components/navigation'
import { useModules, useSetupModulesForPage } from 'src/contexts/modules'
import { PageNameProvider } from 'src/contexts/pageName'
import { useTrackPageView } from 'src/contexts/tracking'

export function Cms() {
  const modules = useModules()

  const topBarToolsContainer = useRef(document.createElement('div'))
  topBarToolsContainer.current.style.width = '100%'
  const mainContainer = useRef(document.createElement('div'))
  mainContainer.current.style.width = '100%'

  useSetupModulesForPage()
  useTrackPageView('Cms')

  useEffect(() => {
    modules.search.mount({ searchBar: topBarToolsContainer.current })
  }, [])

  return (
    <PageNameProvider value="cms">
      <Navigation
        main={mainContainer.current}
        topBarTools={topBarToolsContainer.current}
        module="cms"
      />
    </PageNameProvider>
  )
}
